import React from 'react'
import NavBar from '../components/common/NavBar'
import TeamHeadline from '../components/about/team/TeamHeadline'
import Divider from '../components/common/Divider'
import TeamContainer from '../components/about/team/TeamContainer'
import CtaInfoPack from '../components/home/CtaInfoPack'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'

const About = ({ data, location }) => {
  const teamMemberNodes = data.allEmployeesJson.edges
  const { newEmployeeImage } = data
  const managementNodes = data.management.edges

  return (
    <>
      <Helmet
        title={'Dedicated development team from Poland | Zaven Wrocław'}
        meta={[
          {
            name: 'description',
            content:
              'Meet Zaven’s team and read more about our software development company located in Poland. Now you can hire the best React, .NET and NodeJS developers!',
          },
          {
            name: 'keywords',
            content:
              'full stack developers, web development Poland, developers outsourcing, top mobile developers Poland',
          },
        ]}
      />
      <NavBar lightTheme location={location} />
      <TeamHeadline />
      <Divider rainbow fullWidth />
      <TeamContainer
        teamMemberNodes={teamMemberNodes}
        managementNodes={managementNodes}
        newEmployeeImage={newEmployeeImage}
      />
      <CtaInfoPack
        infoPackUrl={`${process.env.GATSBY_MAILING_URL}/api/infopack`}
      />
    </>
  )
}

export default About

export const teamQuery = graphql`
  query TeamPageQuery {
    allEmployeesJson {
      ...Employees
    }
    management: allManagementJson {
      ...Management
    }
    newEmployeeImage: imageSharp(
      fluid: { originalName: { regex: "/zaven_www_nowy_pracownik/" } }
    ) {
      fluid(quality: 100) {
        ...GatsbyImageSharpFluid_noBase64
      }
    }
  }
`
