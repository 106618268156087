import React from 'react'

const TeamHeadline = () => (
  <section className="py-5 bg-lightest">
    <div className="container">
      <h1 className="seo-h3">About us</h1>
      <p className="seo-h1 text-lowercase">
        The people <br />
        <strong>you can rely on</strong>
      </p>
      <p className="lead">
        The quality of an app is more than the sum of its parts. The same goes
        for a great team. Our team of highly qualified programmers, analysts,
        designers and managers works hard to create useful and attractive
        software and to build long-term business relationships in our unique and
        innovative ecosystem.
      </p>
    </div>
  </section>
)

export default TeamHeadline
