import React from 'react'
import Link from '../../common/Link'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGooglePlusG, faLinkedin } from '@fortawesome/fontawesome-free-brands'
import Img from 'gatsby-image'

const TeamContainer = ({
  teamMemberNodes,
  managementNodes,
  newEmployeeImage,
}) => {
  const getIcon = iconName => {
    switch (iconName) {
      case 'fa-google-plus-g':
        return faGooglePlusG
      case 'fa-linkedin':
        return faLinkedin
      default:
        return null
    }
  }

  const renderTeamManagement = nodes => {
    return nodes.map(({ node }, index) => (
      <div
        key={index}
        className="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-xs-12 employee-wrapper"
      >
        <div className="card card-outline employee">
          <div>
            <Img
              fluid={node.avatar.childImageSharp.fluid}
              alt={node.name}
              className="card-img-top img-responsive avatar"
            />
          </div>
          <div className="card-body description">
            <h3 className="name">{`${node.name} ${node.surname}`}</h3>
            <p className="role">{node.role}</p>
            <p className="social-icons">
              {node.social.map((social, index) => (
                <a
                  key={index}
                  href={social.url}
                  className="link-unstyled"
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                >
                  <FontAwesomeIcon
                    icon={getIcon(social.icon_class)}
                    style={{ margin: '0.1rem' }}
                  />
                </a>
              ))}
            </p>
          </div>
        </div>
      </div>
    ))
  }

  const renderTeamMembers = nodes => {
    return nodes.map(({ node }, index) => (
      <div
        key={index}
        className="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-xs-12 employee-wrapper"
      >
        <div className="card card-outline employee">
          <div>
            <Img
              fluid={node.avatar.childImageSharp.fluid}
              alt={node.name}
              className="card-img-top img-responsive avatar"
            />
          </div>
          <div className="card-body description">
            <h3 className="name">{`${node.name} ${node.surname}`}</h3>
            <p className="role">{node.role}</p>
            <p className="social-icons">
              {node.social.map((social, index) => (
                <a
                  key={index}
                  href={social.url}
                  className="link-unstyled"
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                >
                  <FontAwesomeIcon
                    icon={getIcon(social.icon_class)}
                    style={{ margin: '0.1rem' }}
                  />
                </a>
              ))}
            </p>
          </div>
        </div>
      </div>
    ))
  }

  return (
    <section id="team">
      <div className="container">
        <div className="row mt-5 d-flex justify-content-center">
          {renderTeamManagement(managementNodes)}
        </div>
        <div className="row">
          {renderTeamMembers(teamMemberNodes)}
          <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-xs-12 employee-wrapper">
            <div className="card employee">
              <div>
                <Img
                  fluid={newEmployeeImage.fluid}
                  alt="join Zaven"
                  className="card-img-top img-responsive avatar"
                />
              </div>
              <div className="card-body description">
                <h3 className="name">Want to join us?</h3>
                <p className="role">
                  <Link to="/career/">See what we can offer</Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default TeamContainer
